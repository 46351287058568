import axios from 'axios'

export default {
    async getPersonal(id) {
        try {
            const response = await axios.get(`/personalShareholders`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async userShareholdersByOffice(id) {
        try {
            const response = await axios.get(`/shareholders/user/office/${id}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async shareholdersNotifications(filters) {
        try {
            const response = await axios.post('/sharedholders/notifications', filters);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getById(id) {
        try {
            const response = await axios.get(`/shareholder/${id}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getBySecuritysocial(ss) {
        try {
            const response = await axios.put(`/shareholder/search/${ss}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/shareholder', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/shareholder/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async delete(data) {
        try {
            const response = await axios.put(`/shareholder/delete/${data.id}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
