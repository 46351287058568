import axios from 'axios'

export default {
    async get() {
        try {
            const response = await axios.get('/representatives');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getRepresentativesOffice(id) {
        try {
            const response = await axios.get(`/representatives/office/${id}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/representative', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/representative/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async delete(data) {
        try {
            const response = await axios.put(`/representative/delete/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
