<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <div class="row">
        <div class="col-4">
          <el-form-item :label="'Type'" prop="type">
            <v-select
              class="style-chooser"
              placeholder="Select Type"
              :options="types"
              label="name"
              :reduce="(type) => type.id"
              v-model="form.type"
            ></v-select>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item :label="'Payment Plan'" prop="paymentplan">
            <v-select
              class="style-chooser"
              placeholder="Select a payment plan"
              :options="paymentplans"
              label="name"
              :reduce="(paymentplan) => paymentplan.id"
              v-model="form.paymentplan"
            ></v-select>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item :label="'Payment Option'" prop="paymentoption">
            <v-select
              class="style-chooser"
              placeholder="Select a payment option"
              :options="paymentoptions"
              label="name"
              :reduce="(paymentoption) => paymentoption.id"
              v-model="form.paymentoption"
            ></v-select>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item :label="'Start On'" prop="starton">
            <el-date-picker
              type="date"
              v-model="form.starton"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a day"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item
            :label="'Fee'"
            prop="fee"
            :rules="[{ pattern: /^[0-9]+$/, message: 'Format fee invalid' }]"
          >
            <el-input
              type="text"
              v-model="form.fee"
              placeholder="e.g 100"
            ></el-input>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item :label="'Contact Date'" prop="contactdate">
            <el-date-picker
              type="date"
              v-model="form.contactdate"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a date"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>
      </div>

      <!-- > ********************* CORPORATION INFO*********************<!-->

      <hr />
      <h4>Corporation Info:</h4>
      <br />
      <div class="row">
        <div class="col-8">
          <el-form-item
            :label="'Client Name'"
            prop="name"
            :rules="[{ required: true, message: 'Name is required' }]"
          >
            <el-input
              type="text"
              v-model="form.name"
              placeholder="Client Name"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item
            :label="'Office'"
            prop="office"
            :rules="[{ required: true, message: 'Office is required' }]"
          >
            <el-select
              class="w-100"
              v-model="form.office"
              placeholder="Select office"
              @change="getUsersOffice"
            >
              <el-option
                v-for="item in offices"
                :key="item.id"
                :label="item.office_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="'Representative'" prop="representative">
            <el-select
              class="w-100"
              v-model="form.representative"
              placeholder="Select representative"
            >
              <el-option
                v-for="item in representatives"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item :label="'Business Classification'" prop="clasification">
            <v-select
              class="style-chooser"
              placeholder="Select clasification"
              :options="clasifications"
              label="name"
              :reduce="(clasification) => clasification.id"
              v-model="form.clasification"
            ></v-select>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item
            :label="'Certification Of Authority'"
            prop="certification"
            :rules="[
              {
                pattern: /^[A-Za-z]{2}(\-){1}(\d){7}$/,
                message: 'Certification Of Authority invalid',
              },
            ]"
          >
            <el-input
              type="text"
              placeholder="e.g AB-6698745"
              v-model="form.certification"
              v-mask="'NN-NNNNNNN'"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="'Federal'" prop="federal">
            <el-input
              type="text"
              v-model="form.federal"
              placeholder="e.g 25-6698745"
              v-mask="'##-NNNNNNN'"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="'Address'" prop="address">
            <el-input
              type="text"
              v-model="form.address"
              placeholder="Client Address"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="'DBA'" prop="dba">
            <el-input
              type="text"
              v-model="form.dba"
              placeholder="e.g Skytown"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="'State'" prop="state">
            <v-select
              class="style-chooser"
              placeholder="Select state"
              :options="states"
              label="name"
              :reduce="(state) => state.id"
              v-model="form.state"
            ></v-select>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="'City'" prop="city">
            <el-input
              type="text"
              v-model="form.city"
              placeholder="e.g Livingston"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item
            :label="'Zip'"
            prop="zip"
            :rules="[{ pattern: /^[0-9]{5}$/, message: 'Format zip invalid' }]"
          >
            <el-input
              type="text"
              v-model="form.zip"
              placeholder="e.g 12345"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="'Business Started'" prop="started">
            <el-date-picker
              type="date"
              v-model="form.started"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a day"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="'Incorporate Date'" prop="incorporate">
            <el-date-picker
              type="date"
              v-model="form.incorporate"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a day"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="$t('corporate.fiscalyear')" prop="fiscalyear">
            <v-select
              class="style-chooser"
              placeholder="Select a day"
              :options="[
                { label: '01/31', value: '01/31' },
                { label: '02/28', value: '02/28' },
                { label: '03/31', value: '03/31' },
                { label: '04/30', value: '04/30' },
                { label: '05/31', value: '05/31' },
                { label: '06/30', value: '06/30' },
                { label: '07/31', value: '07/31' },
                { label: '08/31', value: '08/31' },
                { label: '09/30', value: '09/30' },
                { label: '10/31', value: '10/31' },
                { label: '11/30', value: '11/30' },
                { label: '12/31', value: '12/31' },
              ]"
              label="label"
              :reduce="(date) => date.value"
              v-model="form.fiscalyear"
            >
            </v-select>
          </el-form-item>
        </div>

        <div class="col-12">
          <el-form-item :label="$t('Status')" prop="status">
            <el-select
              v-model="form.status"
              placeholder="Select Status"
              class="w-100"
            >
              <el-option value="1" label="Active">Active</el-option>
              <el-option value="0" label="Inactive">Inactive</el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="col-6">
          <el-form-item :label="'Active Date'" prop="activedate">
            <el-date-picker
              type="date"
              v-model="form.activedate"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a date"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>

        <div class="col-6">
          <el-form-item :label="'Inactive Date'" prop="inactivedate">
            <el-date-picker
              type="date"
              v-model="form.inactivedate"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a date"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>
      </div>

      <strong>Services:</strong><br />

      <multiselect
        v-model="value"
        :options="services"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Select Services"
        label="name"
        track-by="id"
        :select-label="''"
        :deselect-label="''"
        @open="openBackgroundOpacity"
        @close="closeBackgroundOpacity"
      >
        <template slot="selection" slot-scope="{ values, search, isOpen }"
          ><span
            class="multiselect__single"
            v-if="values.length &amp;&amp; !isOpen"
            >{{ values.length }} options selected</span
          ></template
        > </multiselect
      ><br />

      <strong>Contact:</strong><br /><br />

      <div class="row">
        <div class="col-4">
          <el-form-item :label="'Web site'" prop="website">
            <el-input
              type="text"
              v-model="form.website"
              placeholder="e.g http(s)://www.google.com"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-md-4">
          <el-form-item
            :label="$t('corporate.phone')"
            prop="phone"
            :rules="[
              {
                pattern: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                message: 'Format phone invalid',
              },
            ]"
          >
            <el-input
              type="text"
              v-model="form.phone"
              placeholder="e.g (414) 639-1121"
              v-mask="'(###) ###-####'"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item
            :label="$t('corporate.fax')"
            prop="fax"
            :rules="[
              {
                pattern: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                message: 'Format phone invalid',
              },
            ]"
          >
            <el-input
              type="text"
              v-model="form.fax"
              placeholder="e.g (414) 639-1121"
              v-mask="'(###) ###-####'"
            ></el-input>
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <el-form-item :label="'Observations'" prop="observations">
            <el-input
              type="textarea"
              v-model="form.observations"
              :rows="5"
            ></el-input>
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <el-button
            v-if="form.new_landlord == true || form.id_landlord != null"
            type="danger"
            @click="remove_landlord()"
          >
            Remove Landlord
          </el-button>
          <el-button v-else type="success" @click="add_landlord()">
            Add Landlord
          </el-button>
          <el-button
            v-if="form.new_formerowner == true || form.id_formerowner != null"
            type="danger"
            @click="remove_formerowner()"
          >
            Remove Former Owner
          </el-button>
          <el-button v-else type="success" @click="add_formerowner()">
            Add Former Owner
          </el-button>
        </div>
        <br />
      </div>

      <!-- > ********************* INFORMACIÓN DEL LANLORD *********************<!-->
      <div v-if="form.new_landlord == true || form.id_landlord != null">
        <hr />
        <h4>LandLord:</h4>
        <br />
      </div>

      <div
        v-if="form.new_landlord == true || form.id_landlord != null"
        class="row"
      >
        <div class="col-12">
          <el-form-item
            :label="$t('landlord.name')"
            prop="name_landlord"
            :rules="[{ required: true, message: 'Name is required' }]"
          >
            <el-input
              type="text"
              v-model="form.name_landlord"
              placeholder="Landlord Name"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-12">
          <el-form-item :label="$t('landlord.address')" prop="address_landlord">
            <el-input
              type="text"
              v-model="form.address_landlord"
              placeholder="Landlord Address"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="'State'" prop="state_landlord">
            <v-select
              class="style-chooser"
              placeholder="Select state"
              :options="states"
              label="name"
              :reduce="(state) => state.id"
              v-model="form.state_landlord"
            ></v-select>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="$t('landlord.city')" prop="city_landlord">
            <el-input
              type="text"
              v-model="form.city_landlord"
              placeholder="e.g Livingston"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item
            :label="'Zip'"
            prop="zip_landlord"
            :rules="[{ pattern: /^[0-9]{5}$/, message: 'Format zip invalid' }]"
          >
            <el-input
              type="text"
              v-model="form.zip_landlord"
              placeholder="e.g 12345"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="$t('landlord.phone')" prop="phone_landlord">
            <el-input
              type="text"
              v-model="form.phone_landlord"
              placeholder="e.g (414) 639-1121"
              v-mask="'(###) ###-####'"
            ></el-input>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item :label="$t('landlord.mobile')" prop="mobile_landlord">
            <el-input
              type="text"
              v-model="form.mobile_landlord"
              placeholder="e.g (414) 639-1121"
              v-mask="'(###) ###-####'"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="$t('landlord.email')" prop="email_landlord">
            <el-input
              type="text"
              v-model="form.email_landlord"
              placeholder="e.g example@email.com"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-12">
          <el-form-item :label="$t('landlord.keep')" prop="note_landlord">
            <el-input
              type="textarea"
              v-model="form.note_landlord"
              :rows="5"
            ></el-input>
          </el-form-item>
        </div>
      </div>

      <!-- > ********************* INFORMACIÓN DEL LANLORD ********************* <!-->

      <!-- > ********************* INFORMACIÓN DEL FORMER OWNER *********************<!-->
      <div v-if="form.new_formerowner == true || form.id_formerowner != null">
        <hr />
        <h4>Former Owner:</h4>
        <br />
      </div>

      <div
        v-if="form.new_formerowner == true || form.id_formerowner != null"
        class="row"
      >
        <div class="col-12">
          <el-form-item
            :label="$t('formerowner.name')"
            prop="name_formerowner"
            :rules="[{ required: true, message: 'Name is required' }]"
          >
            <el-input
              type="text"
              v-model="form.name_formerowner"
              placeholder="Former Owner Name"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-12">
          <el-form-item
            :label="$t('formerowner.address')"
            prop="address_formerowner"
          >
            <el-input
              type="text"
              v-model="form.address_formerowner"
              placeholder="Former Owner Address"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="'State'" prop="state_formerowner">
            <v-select
              class="style-chooser"
              placeholder="Select state"
              :options="states"
              label="name"
              :reduce="(state) => state.id"
              v-model="form.state_formerowner"
            ></v-select>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item :label="$t('formerowner.city')" prop="city_formerowner">
            <el-input
              type="text"
              v-model="form.city_formerowner"
              placeholder="e.g Livingston"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item
            :label="'Zip'"
            prop="zip_formerowner"
            :rules="[{ pattern: /^[0-9]{5}$/, message: 'Format zip invalid' }]"
          >
            <el-input
              type="text"
              v-model="form.zip_formerowner"
              placeholder="e.g 12345"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item
            :label="$t('formerowner.phone')"
            prop="phone_formerowner"
          >
            <el-input
              type="text"
              v-model="form.phone_formerowner"
              placeholder="e.g (414) 639-1121"
              v-mask="'(###) ###-####'"
            ></el-input>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item
            :label="$t('formerowner.mobile')"
            prop="mobile_formerowner"
          >
            <el-input
              type="text"
              v-model="form.mobile_formerowner"
              placeholder="e.g (414) 639-1121"
              v-mask="'(###) ###-####'"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-4">
          <el-form-item
            :label="$t('formerowner.email')"
            prop="email_formerowner"
          >
            <el-input
              type="text"
              v-model="form.email_formerowner"
              placeholder="e.g example@email.com"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-12">
          <el-form-item :label="$t('formerowner.keep')" prop="note_formerowner">
            <el-input
              type="textarea"
              v-model="form.note_formerowner"
              :rows="5"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <!-- > ********************* INFORMACIÓN DEL LANLORD ********************* <!-->

      <!-- > ********************* INFORMACIÓN DEL SHAREHOLDER ********************* <!-->

      <hr />
      <h4>Shareholders</h4>

      <div class="row">
        <div class="col-12">
          <br />
          <el-button type="success" @click="add_shareholder()"
            >Add Shareholder</el-button
          >
        </div>

        <div class="col-12">
          <el-table :data="this.form.shareholders" class="w-100">
            <el-table-column label="Last Name" sortable prop="lastname">
            </el-table-column>
            <el-table-column label="First Name" sortable prop="name">
            </el-table-column>
            <el-table-column
              label="Share"
              sortable
              prop="share"
            ></el-table-column>
            <el-table-column
              label="Address"
              sortable
              prop="address"
            ></el-table-column>
            <el-table-column
              label="Email"
              sortable
              prop="email"
            ></el-table-column>
            <el-table-column label="Operations">
              <template slot-scope="scope">
                <el-button-group>
                  <el-button
                    icon="el-icon-edit"
                    @click="updateshareholder(scope.row, scope.$index)"
                  ></el-button>
                  <el-button
                    icon="el-icon-delete"
                    @click="removeshareholder(scope.$index)"
                  ></el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <modal
        name="shareholder-modal"
        height="auto"
        width="900px"
        :scrollable="true"
        :draggable="true"
      >
        <div class="row" style="padding: 15px">
          <div class="col-12 mb-5 el-drawer__header">
            <span role="heading">Create New Shareholder</span>
          </div>
          <el-form
            :model="formshareholder"
            ref="formshareholder"
            :hide-required-asterisk="true"
          >
            <div class="row" style="padding: 15px">
              <div class="col-6">
                <el-form-item :label="$t('shareholder.title')" prop="title">
                  <v-select
                    class="style-chooser"
                    placeholder="Select title"
                    :options="titles"
                    label="name"
                    :reduce="(title) => title.id"
                    v-model="formshareholder.title"
                  ></v-select>
                </el-form-item>
              </div>

              <div class="col-6">
                <el-form-item
                  :label="$t('shareholder.share')"
                  prop="share"
                  :rules="[{ required: true, message: 'Share is required' }]"
                >
                  <el-input
                    type="text"
                    v-model="formshareholder.share"
                    placeholder="%"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-12">
                <hr />
                <h5>Taxpayer:</h5>
                <br />
              </div>

              <div class="col-3">
                <el-form-item
                  :label="$t('shareholder.firstname')"
                  prop="name"
                  :rules="[
                    { required: true, message: 'The field is required' },
                  ]"
                >
                  <el-input
                    type="text"
                    v-model="formshareholder.name"
                    placeholder="Name"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-3">
                <el-form-item
                  :label="$t('shareholder.lastname')"
                  prop="lastname"
                  :rules="[
                    { required: true, message: 'The field is required' },
                  ]"
                >
                  <el-input
                    type="text"
                    v-model="formshareholder.lastname"
                    placeholder="Last Name"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-3">
                <el-form-item
                  :label="$t('shareholder.socialsecurity')"
                  prop="securitysocial"
                  :rules="[
                    { required: true, message: 'Security social is required' },
                  ]"
                >
                  <el-input
                    type="text"
                    @change="searchShareholder"
                    v-model="formshareholder.securitysocial"
                    placeholder="e.g 554-55-998"
                    v-mask="'NNN-NN-NNNN'"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-3">
                <el-form-item
                  :label="$t('shareholder.birthday')"
                  prop="birthday"
                >
                  <el-date-picker
                    type="date"
                    v-model="formshareholder.birthday"
                    value-format="yyyy-MM-dd"
                    format="MM/dd/yyyy"
                    placeholder="Pick a day"
                    class="w-100"
                  ></el-date-picker>
                </el-form-item>
              </div>

              <div class="col-6">
                <el-form-item :label="$t('shareholder.email')" prop="email">
                  <el-input
                    type="email"
                    v-model="formshareholder.email"
                    placeholder="example@mail.com"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-3">
                <el-form-item :label="$t('shareholder.phone')" prop="phone">
                  <el-input
                    type="text"
                    v-model="formshareholder.phone"
                    placeholder="e.g (414) 639-1121"
                    v-mask="'(###) ###-####'"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-3">
                <el-form-item :label="'Language'" prop="language">
                  <v-select
                    class="style-chooser"
                    placeholder="Select language"
                    :options="['English', 'Spanish']"
                    v-model="formshareholder.language"
                  ></v-select>
                </el-form-item>
              </div>

              <div class="col-12">
                <el-form-item :label="$t('shareholder.address')" prop="address">
                  <el-input
                    type="text"
                    v-model="formshareholder.address"
                    placeholder="Address"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-6">
                <el-form-item :label="$t('shareholder.city')" prop="city">
                  <el-input
                    type="text"
                    v-model="formshareholder.city"
                    placeholder="e.g Livingston"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-3">
                <el-form-item :label="'State'" prop="state">
                  <v-select
                    class="style-chooser"
                    placeholder="Select state"
                    :options="states"
                    label="name"
                    :reduce="(state) => state.id"
                    v-model="formshareholder.state"
                  ></v-select>
                </el-form-item>
              </div>

              <div class="col-3">
                <el-form-item
                  :label="$t('shareholder.zip')"
                  prop="zip"
                  :rules="[
                    { pattern: /^[0-9]{5}$/, message: 'Format zip invalid' },
                  ]"
                >
                  <el-input
                    type="text"
                    v-model="formshareholder.zip"
                    placeholder="e.g 12345"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-12">
                <hr class="mt-0" />
                <br />
              </div>
              <div class="col-6">
                <el-form-item :label="'Active Date'" prop="activedate">
                  <el-date-picker
                    type="date"
                    v-model="formshareholder.activedate"
                    value-format="yyyy-MM-dd"
                    format="MM/dd/yyyy"
                    placeholder="Pick a date"
                    class="w-100"
                  ></el-date-picker>
                </el-form-item>
              </div>

              <div class="col-6">
                <el-form-item :label="'Inactive Date'" prop="inactivedate">
                  <el-date-picker
                    type="date"
                    v-model="formshareholder.inactivedate"
                    value-format="yyyy-MM-dd"
                    format="MM/dd/yyyy"
                    placeholder="Pick a date"
                    class="w-100"
                  ></el-date-picker>
                </el-form-item>
              </div>
              <div class="col-12 mb-4">
                <el-button
                  type="success"
                  class="float-right"
                  @click="savedataFormshareholder('formshareholder')"
                  >Save Shareholder</el-button
                >
              </div>
            </div>
          </el-form>
        </div>
      </modal>

      <!-- > ********************* INFORMACIÓN DEL SHAREHOLDER ********************* <!-->

      <!-- > ********************* INFORMACIÓN DEL BANK CREDENTIALS ********************* <!-->

      <hr />
      <h4>Bank Credentials</h4>

      <div class="row">
        <div class="col-12">
          <br />
          <el-button type="success" @click="show_form_bankcredential()"
            >Add Bank Credential</el-button
          >
        </div>

        <div class="col-12">
          <el-table :data="this.form.bank_credentials" class="w-100">
            <el-table-column label="Bank" sortable prop="bank">
              <template scope="scope">
                <span v-if="scope.row.bank != null">{{
                  banks.filter(function (e) {
                    return e.id === scope.row.bank;
                  })[0].name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Link"
              sortable
              prop="link"
            ></el-table-column>
            <el-table-column label="Type" sortable prop="type">
              <template scope="scope">
                <span v-if="scope.row.type != null">{{
                  bankAccounts.filter(function (e) {
                    return e.id === scope.row.type;
                  })[0].name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="User"
              sortable
              prop="user"
            ></el-table-column>
            <el-table-column label="Operations">
              <template slot-scope="scope">
                <el-button-group>
                  <el-button
                    icon="el-icon-edit"
                    @click="updatebankcredential(scope.row, scope.$index)"
                  ></el-button>
                  <el-button
                    icon="el-icon-delete"
                    @click="removebankcredential(scope.$index)"
                  ></el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <modal
        name="bankcredential-modal"
        height="auto"
        :scrollable="true"
        :draggable="true"
      >
        <div class="row" style="padding: 15px">
          <div class="col-12 mb-5 el-drawer__header">
            <span role="heading">Create Bank Credential</span>
          </div>
          <el-form
            :model="formbankcredential"
            ref="formbankcredential"
            :hide-required-asterisk="true"
          >
            <div class="row" style="padding: 15px">
              <div class="col-4">
                <el-form-item :label="$t('bank.bank')" prop="bank">
                  <el-select
                    v-model="formbankcredential.bank"
                    placeholder="Select bank"
                    class="w-100"
                  >
                    <el-option
                      v-for="item in banks"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="col-4">
                <el-form-item :label="$t('bank.link')" prop="link">
                  <el-input
                    type="text"
                    v-model="formbankcredential.link"
                    placeholder="Link of web"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-4">
                <el-form-item :label="$t('bank.type')" prop="type">
                  <el-select
                    v-model="formbankcredential.type"
                    placeholder="Select type"
                    class="w-100"
                  >
                    <el-option
                      v-for="item in bankAccounts"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="col-4">
                <el-form-item :label="$t('bank.user')" prop="user">
                  <el-input
                    type="text"
                    v-model="formbankcredential.user"
                    placeholder="User in Bank"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-4">
                <el-form-item :label="$t('bank.password')" prop="password">
                  <el-input
                    type="text"
                    v-model="formbankcredential.password"
                    placeholder="Password"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-4">
                <el-form-item :label="$t('bank.routing')" prop="routing">
                  <el-input
                    type="text"
                    v-model="formbankcredential.routing"
                    placeholder="Routing"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-12">
                <el-form-item :label="$t('bank.account')" prop="account">
                  <el-input
                    type="text"
                    v-model="formbankcredential.account"
                    placeholder="Bank Account"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-12">
                <el-form-item :label="'Notes'" prop="notes">
                  <el-input
                    type="text"
                    v-model="formbankcredential.notes"
                    placeholder="Notes"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-12 mb-4">
                <el-button
                  type="success"
                  class="float-right"
                  @click="savedataFormbankcredential('formbankcredential')"
                  >Save Bank Credential</el-button
                >
              </div>
            </div>
          </el-form>
        </div>
      </modal>

      <!-- > ********************* INFORMACIÓN DEL BANK CREDENTIALS ********************* <!-->

      <!-- > ********************* INFORMACIÓN DEL CREDENTIALS ********************* <!-->

      <hr />
      <h4>Credentials</h4>

      <div class="row">
        <div class="col-12" style="padding: 10px">
          <br />
          <el-button type="success" @click="show_form_credential()"
            >Add Credential</el-button
          >
        </div>

        <div class="col-12">
          <el-table :data="this.form.credentials" class="w-100">
            <el-table-column label="Description" sortable prop="input">
              <template scope="scope">
                <span v-if="scope.row.input != null">{{
                  inputs.filter(function (e) {
                    return e.id === scope.row.input;
                  })[0].name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Link"
              sortable
              prop="link"
            ></el-table-column>
            <el-table-column
              label="User"
              sortable
              prop="user"
            ></el-table-column>
            <el-table-column label="Pin" sortable prop="pin"></el-table-column>
            <el-table-column label="Operations">
              <template slot-scope="scope">
                <el-button-group>
                  <el-button
                    icon="el-icon-edit"
                    @click="updatecredential(scope.row, scope.$index)"
                  ></el-button>
                  <el-button
                    icon="el-icon-delete"
                    @click="removecredential(scope.$index)"
                  ></el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <modal
        name="credential-modal"
        height="auto"
        :scrollable="true"
        :draggable="true"
      >
        <div class="row" style="padding: 15px">
          <div class="col-12 mb-5 el-drawer__header">
            <span role="heading">Create Credential</span>
          </div>
          <el-form
            :model="formcredentials"
            ref="formcredentials"
            :hide-required-asterisk="true"
          >
            <div class="row" style="padding: 15px">
              <div class="col-6">
                <el-form-item
                  :label="$t('credential.description')"
                  prop="input"
                >
                  <el-select
                    v-model="formcredentials.input"
                    placeholder="Select description"
                    class="w-100"
                  >
                    <el-option
                      v-for="item in inputs"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="col-6">
                <el-form-item :label="$t('credential.link')" prop="link">
                  <el-input
                    type="text"
                    v-model="formcredentials.link"
                    placeholder="Insert Link"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-4">
                <el-form-item :label="$t('credential.user')" prop="user">
                  <el-input
                    type="text"
                    v-model="formcredentials.user"
                    placeholder="Insert User"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-4">
                <el-form-item
                  :label="$t('credential.password')"
                  prop="password"
                >
                  <el-input
                    type="text"
                    v-model="formcredentials.password"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-4">
                <el-form-item :label="$t('credential.pin')" prop="pin">
                  <el-input
                    type="text"
                    v-model="formcredentials.pin"
                    placeholder="Insert Pin"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-12">
                <el-form-item :label="'Notes'" prop="notes">
                  <el-input
                    type="text"
                    v-model="formcredentials.notes"
                    placeholder="Notes"
                  ></el-input>
                </el-form-item>
              </div>

              <div class="col-12 mb-4">
                <el-button
                  type="success"
                  class="float-right"
                  @click="savedataFormcredential('formcredentials')"
                  >Save Credential</el-button
                >
              </div>
            </div>
          </el-form>
        </div>
      </modal>

      <!-- > ********************* INFORMACIÓN DEL CREDENTIALS ********************* <!-->

      <div class="row">
        <div class="col-12">
          <br />
          <el-button
            type="primary"
            @click="!data ? submitForm('form') : updateForm('form')"
            >Save Data</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import location from "@/services/api/location";
import clasification from "@/services/api/clasification";
import shareholder from "@/services/api/shareholder";
import type from "@/services/api/type";
import title from "@/services/api/title";
import paymentOption from "@/services/api/paymentOption";
import paymentPlan from "@/services/api/paymentPlan";
import bank from "@/services/api/bank";
import bankAccount from "@/services/api/bankAccount";
import credentialInput from "@/services/api/credentialInput";
import corporate from "@/services/api/corporate";
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import representative from "@/services/api/representative";
import service from "@/services/api/service";
import Multiselect from "vue-multiselect";

export default {
  props: ["data"],
  data() {
    return {
      inputs: [],
      bankAccounts: [],
      banks: [],
      states: [],
      offices: [],
      usersShareholder: [],
      representatives: [],
      clasifications: [],
      types: [],
      titles: [],
      paymentoptions: [],
      paymentplans: [],
      services: [],
      value: [],
      updateshareholerindex: false,
      updatebankcredentialindex: false,
      updatecredentialindex: false,
      form: {
        type: null,
        paymentplan: null,
        paymentoption: null,
        starton: null,
        fee: null,
        name: null,
        status: "1",
        activedate: null,
        inactivedate: null,
        contactdate: null,
        address: null,
        dba: null,
        copyOffice: null,
        office: null,
        representative: null,
        website: null,
        phone: null,
        fax: null,
        state: null,
        city: null,
        zip: null,
        clasification: null,
        started: null,
        incorporate: null,
        fiscalyear: null,
        certification: null,
        federal: null,
        service: null,
        observations: null,
        new_landlord: false,
        name_landlord: null,
        address_landlord: null,
        city_landlord: null,
        state_landlord: null,
        zip_landlord: null,
        phone_landlord: null,
        mobile_landlord: null,
        email_landlord: null,
        note_landlord: null,
        new_formerowner: false,
        name_formerowner: null,
        address_formerowner: null,
        city_formerowner: null,
        state_formerowner: null,
        zip_formerowner: null,
        phone_formerowner: null,
        mobile_formerowner: null,
        email_formerowner: null,
        note_formerowner: null,
        changeshareholders: [],
        shareholders: [],
        changebank_credentials: [],
        bank_credentials: [],
        changecredentials: [],
        credentials: [],
      },
      formshareholder: {
        new: true,
        office: null,
        name: null,
        lastname: null,
        title: null,
        securitysocial: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        email: null,
        phone: null,
        birthday: null,
        language: null,
        activedate: null,
        inactivedate: null,
      },
      formbankcredential: {
        bank: null,
        link: null,
        type: null,
        user: null,
        password: null,
        routing: null,
        account: null,
        notes: null,
      },
      formcredentials: {
        input: null,
        link: null,
        user: null,
        password: null,
        pin: null,
        notes: null,
      },
    };
  },
  components: { Multiselect },
  mounted() {
    location.state().then((response) => {
      this.states = response;
    });
    clasification.get().then((response) => {
      this.clasifications = response;
    });
    type.get().then((response) => {
      this.types = response;
    });
    title.get().then((response) => {
      this.titles = response;
    });
    paymentOption.get().then((response) => {
      this.paymentoptions = response;
    });
    paymentPlan.get().then((response) => {
      this.paymentplans = response;
    });
    bank.get().then((response) => {
      this.banks = response;
    });
    bankAccount.get().then((response) => {
      this.bankAccounts = response;
    });
    service.get().then((response) => {
      this.services = response;
    });
    credentialInput.get().then((response) => {
      this.inputs = response;
    });
    switch (this.$store.getters.discriminator) {
      case "administrator":
        office.get().then((response) => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee":
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
        });
        break;
    }
    //
    if (this.data) {
      this.form.id = this.data.id;
      this.form.type = this.data.typeId;
      this.form.paymentplan = this.data.paymentPlanId;
      this.form.paymentoption = this.data.paymentOptionId;
      this.form.starton = this.data.startOn;
      this.form.fee = this.data.fee;
      this.form.contactdate = this.data.contactDate;
      this.form.name = this.data.name;
      this.form.status =
        this.data.status != null ? this.data.status.toString() : "1";
      this.form.activedate = this.data.activeDate;
      this.form.inactivedate = this.data.inactiveDate;
      this.form.address = this.data.address;
      this.form.dba = this.data.dba;
      this.form.office = this.data.officeId;
      this.form.copyOffice = this.data.officeId;
      this.getUsersOffice();
      this.form.representative = this.data.representativeId;
      this.form.website = this.data.website;
      this.form.phone = this.data.phone;
      this.form.fax = this.data.fax;
      this.form.state = this.data.stateId;
      if (this.data.city != null) {
        this.form.city = this.data.city.name;
      }
      this.form.zip = this.data.zip;
      this.form.clasification = this.data.clasificationId;
      this.form.started = this.data.started;
      this.form.incorporate = this.data.incorporate;
      this.form.fiscalyear = this.data.fiscalYear;
      this.form.certification = this.data.certification;
      this.form.federal = this.data.federal;
      this.form.service = this.data.service;
      this.form.observations = this.data.observations;
      this.form.shareholders = this.data.shareholders.map(function (sh) {
        let item = {
          id: sh.id,
          new: false,
          office: sh.officeId,
          title: sh.titleId,
          share: sh.pivot.share,
          name: sh.name,
          lastname: sh.lastName,
          securitysocial: sh.securitySocial,
          address: sh.address,
          zip: sh.zip,
          city: null,
          state: null,
          email: sh.email,
          phone: sh.phone,
          birthday: sh.birthDay,
          language: sh.languaje,
          activedate: sh.activeDate,
          inactivedate: sh.inactiveDate,
        };
        if (sh.city != null) {
          item.city = sh.city.name;
          item.state = sh.city.stateId;
        }
        return item;
      });
      this.form.credentials = this.data.credentials.map(function (c) {
        return {
          id: c.id,
          input: c.inputId,
          link: c.link,
          user: c.user,
          password: c.password,
          pin: c.pin,
          notes: c.notes,
        };
      });
      this.form.bank_credentials = this.data.credential_banks.map(function (
        cb
      ) {
        return {
          id: cb.id,
          bank: cb.bankId,
          link: cb.link,
          type: cb.typeId,
          user: cb.user,
          password: cb.password,
          routing: cb.routing,
          account: cb.account,
          notes: cb.notes,
        };
      });
      if (this.data.landlord != null) {
        this.form.id_landlord = this.data.landlord.id;
        this.form.name_landlord = this.data.landlord.name;
        this.form.address_landlord = this.data.landlord.address;
        if (this.data.landlord.city != null) {
          this.form.state_landlord = this.data.landlord.city.stateId;
          this.form.city_landlord = this.data.landlord.city.name;
        }
        this.form.zip_landlord = this.data.landlord.zip;
        this.form.phone_landlord = this.data.landlord.phone;
        this.form.mobile_landlord = this.data.landlord.mobile;
        this.form.email_landlord = this.data.landlord.email;
        this.form.note_landlord = this.data.landlord.note;
      } else {
        this.form.id_landlord = null;
      }
      if (this.data.formerowner != null) {
        this.form.id_formerowner = this.data.formerowner.id;
        this.form.name_formerowner = this.data.formerowner.name;
        this.form.address_formerowner = this.data.formerowner.address;
        if (this.data.formerowner.city != null) {
          this.form.state_formerowner = this.data.formerowner.city.stateId;
          this.form.city_formerowner = this.data.formerowner.city.name;
        }
        this.form.zip_formerowner = this.data.formerowner.zip;
        this.form.phone_formerowner = this.data.formerowner.phone;
        this.form.mobile_formerowner = this.data.formerowner.mobile;
        this.form.email_formerowner = this.data.formerowner.email;
        this.form.note_formerowner = this.data.formerowner.note;
      } else {
        this.form.id_formerowner = null;
      }
    }
  },

  methods: {
    getUsersOffice() {
      representative
        .getRepresentativesOffice(this.form.office)
        .then((response) => {
          this.representatives = response;
        });
    },
    add_landlord() {
      this.form.new_landlord = true;
    },
    add_formerowner() {
      this.form.new_formerowner = true;
    },
    remove_landlord() {
      this.form.new_landlord = false;
    },
    remove_formerowner() {
      this.form.new_formerowner = false;
    },
    add_shareholder() {
      this.$prompt("Input Security Social Number").then((ss) => {
        if (ss) {
          switch (ss.value.length) {
            case 9:
              this.formshareholder.securitysocial = ss.value.replace(
                /^([0-9]{3})([0-9]{2})([0-9]{4})$/,
                "$1-$2-$3"
              );
              this.searchShareholder();
              this.show_form_shareholder();
              break;
            case 11:
              this.formshareholder.securitysocial = ss.value;
              this.searchShareholder();
              this.show_form_shareholder();
              break;
          }
        }
      });
    },
    show_form_shareholder() {
      this.$modal.show("shareholder-modal");
    },
    hide_form_shareholder() {
      this.$modal.hide("shareholder-modal");
      this.updateshareholerindex = false;
    },

    show_form_bankcredential() {
      this.$modal.show("bankcredential-modal");
    },

    hide_form_bankcredential() {
      this.$modal.hide("bankcredential-modal");
      this.updatebankcredentialindex = false;
    },

    show_form_credential() {
      this.$modal.show("credential-modal");
    },

    hide_form_credential() {
      this.$modal.hide("credential-modal");
      this.updatecredentialindex = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let totalShare = 0;
          this.form.shareholders.forEach((sh) => {
            totalShare += parseInt(sh.share, 10);
          });
          if (totalShare == 100 || this.form.shareholders.length === 0) {
            var shareholders = JSON.stringify(this.form.shareholders);
            delete this.form.shareholders;
            this.form.shareholders = shareholders;

            var bank_credentials = JSON.stringify(this.form.bank_credentials);
            delete this.form.bank_credentials;
            this.form.bank_credentials = bank_credentials;

            var credentials = JSON.stringify(this.form.credentials);
            delete this.form.credentials;
            this.form.credentials = credentials;
            corporate
              .create(this.form)
              .then((response) => {
                this.$emit("refresh", { data: response, update: false });
              })
              .catch(() => {
                this.$message({
                  message: "Opps... Something wrong",
                  type: "error",
                  customClass: "message-error",
                });
              });
          } else {
            this.$swal({
              title: "Shareholders!",
              text: "The sum of share must be 100%",
              icon: "error",
            });
          }
        } else {
          this.$swal({
            title: "Error!",
            text: "Form is not valid!",
            icon: "error",
          });
        }
      });
    },
    searchShareholder() {
      if (this.formshareholder.securitysocial != null) {
        shareholder
          .getBySecuritysocial(this.formshareholder.securitysocial)
          .then((response) => {
            if (response.id != null) {
              this.formshareholder.new = false;
              this.formshareholder.id = response.id;
              this.formshareholder.name = response.name;
              this.formshareholder.lastname = response.lastName;
              this.formshareholder.title = response.titleId;
              this.formshareholder.address = response.address;
              this.formshareholder.zip = response.zip;
              if (response.city != null) {
                this.formshareholder.city = response.city.name;
                this.formshareholder.state = response.city.stateId;
              }
              this.formshareholder.email = response.email;
              this.formshareholder.phone = response.phone;
              this.formshareholder.birthday = response.birthDay;
              this.formshareholder.language = response.languaje;
            } else {
              this.formshareholder.new = true;
            }
          })
          .catch(() => {
            this.$message({
              message: "Opps... Something wrong",
              type: "error",
              customClass: "message-error",
            });
          });
      }
    },
    savedataFormshareholder(formName) {
      if (this.updateshareholerindex === false) {
        this.submitFormshareholder(formName);
      } else {
        this.updateFormshareholder(formName);
      }
    },
    submitFormshareholder(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.shareholders.push(Object.assign({}, this.formshareholder));
          this.formshareholder.new = true;
          this.formshareholder.name = null;
          this.formshareholder.title = null;
          this.formshareholder.securitysocial = null;
          this.formshareholder.search = false;
          this.formshareholder.address = null;
          this.formshareholder.city = null;
          this.formshareholder.state = null;
          this.formshareholder.zip = null;
          this.formshareholder.share = null;
          this.formshareholder.email = null;
          this.formshareholder.phone = null;
          this.formshareholder.birthday = null;
          this.formshareholder.language = null;
          this.hide_form_shareholder();
        } else {
          return false;
        }
      });
    },
    updateFormshareholder(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.shareholders.splice(this.updateshareholerindex, 1);
          this.form.shareholders.push(Object.assign({}, this.formshareholder));
          this.formshareholder.new = true;
          this.formshareholder.name = null;
          this.formshareholder.title = null;
          this.formshareholder.securitysocial = null;
          this.formshareholder.search = false;
          this.formshareholder.address = null;
          this.formshareholder.city = null;
          this.formshareholder.state = null;
          this.formshareholder.zip = null;
          this.formshareholder.share = null;
          this.formshareholder.zip = null;
          this.formshareholder.email = null;
          this.formshareholder.phone = null;
          this.formshareholder.birthday = null;
          this.hide_form_shareholder();
          this.updateshareholerindex = false;
        } else {
          return false;
        }
      });
    },
    removeshareholder(index) {
      if (this.data != null) {
        this.form.changeshareholders.push({
          remove: this.form.shareholders[index].id,
        });
      }
      this.form.shareholders.splice(index, 1);
    },
    updateshareholder(row, index) {
      this.formshareholder = Object.assign({}, row);
      this.updateshareholerindex = index;
      this.show_form_shareholder();
    },
    savedataFormbankcredential(formName) {
      if (this.updatebankcredentialindex === false) {
        this.submitFormbankcredential(formName);
      } else {
        this.updateFormbankcredential(formName);
      }
    },
    submitFormbankcredential(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.data != null) {
            var virtualId = Math.floor(Math.random() * 100);
            this.formbankcredential.virtualId = virtualId;
          }
          this.form.bank_credentials.push(
            Object.assign({}, this.formbankcredential)
          );
          this.formbankcredential.bank = null;
          this.formbankcredential.link = null;
          this.formbankcredential.type = null;
          this.formbankcredential.user = null;
          this.formbankcredential.password = null;
          this.formbankcredential.routing = null;
          this.formbankcredential.account = null;
          this.hide_form_bankcredential();
        } else {
          return false;
        }
      });
    },
    updateFormbankcredential(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.bank_credentials.splice(this.updatebankcredentialindex, 1);
          this.form.bank_credentials.push(
            Object.assign({}, this.formbankcredential)
          );
          this.formbankcredential.bank = null;
          this.formbankcredential.link = null;
          this.formbankcredential.type = null;
          this.formbankcredential.user = null;
          this.formbankcredential.password = null;
          this.formbankcredential.routing = null;
          this.formbankcredential.account = null;
          this.hide_form_bankcredential();
          this.updatebankcredentialindex = false;
        } else {
          return false;
        }
      });
    },
    removebankcredential(index) {
      if (this.data != null) {
        this.form.changebank_credentials.push({
          remove: this.form.bank_credentials[index].id,
        });
      }
      this.form.bank_credentials.splice(index, 1);
    },
    updatebankcredential(row, index) {
      this.formbankcredential = Object.assign({}, row);
      this.updatebankcredentialindex = index;
      this.show_form_bankcredential();
    },
    savedataFormcredential(formName) {
      if (this.updatecredentialindex === false) {
        this.submitFormcredential(formName);
      } else {
        this.updateFormcredential(formName);
      }
    },
    submitFormcredential(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.data != null) {
            var virtualId = Math.floor(Math.random() * 100);
            this.formcredentials.virtualId = virtualId;
          }
          this.form.credentials.push(Object.assign({}, this.formcredentials));
          this.formcredentials.input = null;
          this.formcredentials.link = null;
          this.formcredentials.user = null;
          this.formcredentials.password = null;
          this.formcredentials.pin = null;
          this.formcredentials.other = null;
          this.hide_form_credential();
        } else {
          return false;
        }
      });
    },
    updateFormcredential(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.credentials.splice(this.updatecredentialindex, 1);
          this.form.credentials.push(Object.assign({}, this.formcredentials));
          this.formcredentials.input = null;
          this.formcredentials.link = null;
          this.formcredentials.user = null;
          this.formcredentials.password = null;
          this.formcredentials.pin = null;
          this.formcredentials.other = null;
          this.hide_form_credential();
          this.updatecredentialindex = false;
        } else {
          return false;
        }
      });
    },
    removecredential(index) {
      if (this.data != null) {
        this.form.changecredentials.push({
          remove: this.form.credentials[index].id,
        });
      }
      this.form.credentials.splice(index, 1);
    },
    updatecredential(row, index) {
      this.formcredentials = Object.assign({}, row);
      this.updatecredentialindex = index;
      this.show_form_credential();
    },
    doUpdate() {
      corporate
        .update(this.form)
        .then((response) => {
          this.$emit("refresh", { data: response, update: true });
        })
        .catch(() => {
          this.$message({
            message: "Opps... Something wrong",
            type: "error",
            customClass: "message-error",
          });
        });
    },
    updateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let totalShare = 0;
          this.form.shareholders.forEach((sh) => {
            totalShare += parseInt(sh.share, 10);
          });
          if (totalShare == 100 || this.form.shareholders.length === 0) {
            var shareholders = JSON.stringify(this.form.shareholders);
            delete this.form.shareholders;
            this.form.shareholders = shareholders;

            var changeshareholders = JSON.stringify(
              this.form.changeshareholders
            );
            delete this.form.changeshareholders;
            this.form.changeshareholders = changeshareholders;

            var bank_credentials = JSON.stringify(this.form.bank_credentials);
            delete this.form.bank_credentials;
            this.form.bank_credentials = bank_credentials;

            var changebank_credentials = JSON.stringify(
              this.form.changebank_credentials
            );
            delete this.form.changebank_credentials;
            this.form.changebank_credentials = changebank_credentials;

            var credentials = JSON.stringify(this.form.credentials);
            delete this.form.credentials;
            this.form.credentials = credentials;

            var changecredentials = JSON.stringify(this.form.changecredentials);
            delete this.form.changecredentials;
            this.form.changecredentials = changecredentials;

            if (this.form.office != this.form.copyOffice) {
              this.$swal({
                title: "Are you sure?",
                text: "You changed the office. Everything files will be moved!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, do it!",
              }).then((result) => {
                if (result.value) {
                  this.doUpdate();
                }
              });
            } else {
              this.doUpdate();
            }
          } else {
            this.$swal({
              title: "Shareholders!",
              text: "The sum of share must be 100%",
              icon: "error",
            });
          }
        } else {
          this.$swal({
            title: "Error!",
            text: "Form is not valid!",
            icon: "error",
          });
        }
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle {
  height: 45px;
}
.style-chooser .vs__dropdown-menu {
  max-height: 150px;
}

.swal2-container {
  z-index: 999999 !important;
}
</style>
