import axios from 'axios'

export default {

    async state(){

        try {
            const response = await axios.get('/location/states');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }

    },


    async city(id) {
        try {
            const response = await axios.get('/location/cities/'+id);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
